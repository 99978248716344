import Vue from 'vue';
import VueRouter from 'vue-router';
import Routes from '@/views/Routes.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/routes',
    name: 'Routes',
    component: Routes,
  },
  {
    component: () => import(/* webpackChunkName: "Error" */'@/views/Error.vue'),
    meta: {
      isPublic: true,
    },
    name: 'Error',
    path: '/error',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
