import { createAwsAxiosInstance, expandResponseStringToCoordinateArray, navigationRouteAg } from '@afrigis/aws-navigation-routes';
import { GettersUtility, MutationsUtility } from '@afrigis/vuex-utilities';

const MUTATION_SET_DATA = 'SetData';
const MUTATION_SET_IS_LOADING = 'SetIsLoading';
const MUTATION_SET_ERROR_MESSAGE = 'SetErrorMessage';

const STATEVAR_DATA = 'Data';
const STATEVAR_IS_LOADING = 'IsLoading';
const STATEVAR_ERROR_MESSAGE = 'ErrorMessage';

const actions = {
  LoadNavigationRoutes: async (context, listOfPoints) => {
    try {
      if (!listOfPoints) {
        throw new Error('Parameter "ListOfPoints" is required');
      }
      context.commit(MUTATION_SET_IS_LOADING, true);
      const { token, key } = context.rootState.addressSearchStore;
      const axiosInstance = await createAwsAxiosInstance(token, key);
      const requestOptions = {
        axiosInstance,
        outputFormat: 'JSON',
        version: '1.1.7',
        routing: {
          routingLayerName: 'AG_STREETS',
          optimisationParam: 'TRAVELTIME',
          listOfPoints,
        },
      };
      const { data, status } = await navigationRouteAg(requestOptions);
      if (status !== 200) {
        throw new Error('An error occured while processing the request');
      }
      const pointsArray = expandResponseStringToCoordinateArray(data.RoutePoints.Points);
      context.commit(MUTATION_SET_DATA, pointsArray);
    } catch {
      throw new Error('Could not load navigation routes');
    } finally {
      context.commit(MUTATION_SET_IS_LOADING, false);
    }
  },
};

const getters = {
  [STATEVAR_DATA]: GettersUtility.getArrayCopy(STATEVAR_DATA),
  [STATEVAR_IS_LOADING]: GettersUtility.get(STATEVAR_IS_LOADING),
  [STATEVAR_ERROR_MESSAGE]: GettersUtility.get(STATEVAR_ERROR_MESSAGE),
};

const mutations = {
  [MUTATION_SET_DATA]: MutationsUtility.set(STATEVAR_DATA),
  [MUTATION_SET_IS_LOADING]: MutationsUtility.set(STATEVAR_IS_LOADING),
  [MUTATION_SET_ERROR_MESSAGE]: MutationsUtility.set(STATEVAR_ERROR_MESSAGE),
};

const state = {
  [STATEVAR_DATA]: [],
  [STATEVAR_IS_LOADING]: false,
  [STATEVAR_ERROR_MESSAGE]: null,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
