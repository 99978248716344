import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      suggestions: 'addressSearchStore/suggestions',
      loadingOriginSuggestions: 'addressSearchStore/loadingOriginSuggestions',
      loadingDestinationSuggestions: 'addressSearchStore/loadingDestinationSuggestions',
      detailedResults: 'addressSearchStore/detailedResults',
      token: 'addressSearchStore/token',
    }),
  },
  data() {
    return {
      autocompleteCharacterTimer: null,
      searchError: null,
    };
  },
  methods: {
    ...mapActions(
      'addressSearchStore',
      ['doAutocomplete', 'doSearch', 'doSearchDetail', 'fetchAwsCredentials', 'searchLastEnteredText'],
    ),
    ...mapMutations(
      'addressSearchStore',
      ['clearDetailedResults', 'clearSuggestions', 'LastEnteredText'],
    ),
    autocomplete(direction, searchText) {
      if (searchText.length < 3) {
        return;
      }
      this.LastEnteredText(searchText);
      if (this.autocompleteCharacterTimer) {
        clearTimeout(this.autocompleteCharacterTimer);
      }
      this.autocompleteCharacterTimer = setTimeout(() => {
        this.searchError = null;
        this.doAutocomplete({ direction, searchText })
          .catch(() => {
            this.searchError = 'Could not Autocomplete';
          });
      }, 300);
    },
  },
};
