<template>
  <b-container fluid>
    <b-row>
      <b-col md="4" class="p-3 text-left">
        <h1>Routes</h1>
      </b-col>
      <b-col md="4" class="ml-auto p-3 text-right appCtrlButtons">
        <b-button
          @click="shareViaWebShare"
          title="Share This App"
          variant="outline-secondary"
        >
          <b-icon-share />
        </b-button>
        <b-modal
          id="modal-1"
          title="Share This App"
          ref="fallback-modal"
          @ok="handleOk"
        >
          <b-form-textarea
            id="share-fallback"
            ref="toCopy"
            v-model="shareUrl"
            rows="1"
            max-rows="6"
          ></b-form-textarea>
          <template #modal-footer="{ ok }">
            <b-button size="sm" variant="primary" @click="ok()">
              Copy URL to Clipboard
            </b-button>
          </template>
        </b-modal>
        <b-button @click="startTour" variant="outline-secondary">
          Start Tour
        </b-button>
      </b-col>
    </b-row>
    <v-tour
      class="routeTour"
      name="routeTour"
      :options="tourOptions"
      :steps="routeTourSteps"
    />
    <contact-expert/>
     <b-row>
      <!--Column 1 -->
      <b-col class="mb-3" lg="8">
        <b-row>
          <b-col md="12" class="mb-2">
            <form
              autocomplete="off" @submit.prevent="submitSearch">
              <float-label fixed>
                <origin-address
                  autofocus
                  class="overlay origin-input"
                  id="originAutocomplete"
                  placeholder="Origin Address"
                  :options="suggestions"
                  :processing="loadingOriginSuggestions"
                  :text="searchOriginText"
                  @cleared="clearOrginAddress"
                  @onChosen="onOriginChosen"
                  @onFocus="fetchAwsCredentials"
                  @onInput="autocompleteOrigin"
                  @onSearch="searchOrigin"
                />
              </float-label>
            </form>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mb-sm-2">
            <form
              autocomplete="off"
              @submit.prevent="submitSearch"
              class="mt-5 pt-2"
            >
              <float-label fixed>
                <destination-address
                  class="overlay"
                  id="destinationAutocomplete"
                  placeholder="Destination Address"
                  :options="suggestions"
                  :processing="loadingDestinationSuggestions"
                  :text="searchDestText"
                  @cleared="clearDestAddress"
                  @onChosen="onDestChosen"
                  @onFocus="fetchAwsCredentials"
                  @onInput="autocompleteDestination"
                  @onSearch="searchDestination"
                />
              </float-label>
            </form>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4" class="py-4 text-left">
            <b-button
              @click="getRoute"
              :disabled="IsLoadingRoutes"
              v-if="CanGenerateRoute"
              class="mt-3"
            >
              Get Route
              <b-spinner small v-if="IsLoadingRoutes" />
            </b-button>
          </b-col>
        </b-row>
        <b-row class="m-1">
          <leaflet-map />
        </b-row>
      </b-col>
      <!--Column 2 -->
      <b-col class="mb-3" lg="4">
        <b-card
          id="trust-us-card"
          :style="{ background: `url(${backgroundImage})` }"
          text-variant="white"
          class="mb-3"
        >
          <div class="content">
            <h5>Trust us for</h5>
            <ul>
              <li>SABS and ISO compliant Address data.</li>
              <li>Accurate and verified address results returned.</li>
              <li>
                Components available via API to integrate into your own solutions.
              </li>
              <li>Over 50 Million searchable address combinations.</li>
            </ul>
          </div>
        </b-card>
        <b-card
          id="route-description"
          class="remove-border-card">
          <h4>Route Description</h4>
          <b-card-text>
            <p>
              Plan your route from point A to point B. Use coordinates or our
              refined address search with verified and validated address data.
              Our address data is SABS and ISO compliant with over 50 million
              searchable addresses.
            </p>
            <h5>Possible uses:</h5>
            <ul>
              <li>
                Accurate route and driving directions from point A to point B.
              </li>
              <li>Enable route planning to optimise trips.</li>
            </ul>
            <h5>Also available:</h5>
            <ul>
              <li>Routing with multiple stops.</li>
              <li>Route fee calculator.</li>
              <li>
                Drive time route solution, e.g. my address + schools data layer
                = driving time for schools in my area.
              </li>
              <li>Integration with Navigator.</li>
              <li>
                Avoid traffic route with updated traffic information layer.
              </li>
              <li>Toll gates and fees.</li>
              <li>
                Quickest-, Shortest-, Walking- and avoiding traffic routes, e.g.
                takes one-way traffic and traffic circles into account.
              </li>
              <li>Enable route planning to optimise trips.</li>
            </ul>
            <p class="mt-3">
              <b-link
                class="linkBoarder"
                target="_blank"
                href="https://developers.afrigis.co.za/portfolio/afrigis-routing/"
                >View Technical Documentation <b-icon-arrow-right
              /></b-link>
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VueTour from 'vue-tour';
import Vue from 'vue';

import { mapActions, mapGetters, mapMutations } from 'vuex';
import AgAutocomplete from '@afrigis/afrigis-vue-autocomplete';
import AddressSearch from '@/mixins/addressSearch';
import FloatLabel from 'vue-float-label/components/FloatLabel.vue';
import LeafletMap from '@/components/LeafletMap.vue';
import oidcAuth from '@/mixins/oidcAuth';
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BContainer,
  BFormTextarea,
  BIconShare,
  BLink,
  BSpinner,
  BIconArrowRight,
  BRow,
} from 'bootstrap-vue';
import ContactExpert from '@/components/ContactExpert.vue';

const backgroundImage = require('@/assets/TTB_Image_1920x300.webp');

Vue.use(VueTour);

export default {
  components: {
    BButton,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BFormTextarea,
    BLink,
    BRow,
    DestinationAddress: AgAutocomplete,
    OriginAddress: AgAutocomplete,
    FloatLabel,
    BIconShare,
    BSpinner,
    BIconArrowRight,
    ContactExpert,
    LeafletMap,
  },
  computed: {
    ...mapGetters({
      DetailedDestResults: 'addressSearchStore/detailedDestResults',
      DetailedOriginResults: 'addressSearchStore/detailedOriginResults',
      IsLoadingRoutes: 'routesStore/IsLoading',
    }),
    backgroundImage: () => backgroundImage,
    CanGenerateRoute() {
      return !!this.DetailedDestResults && !!this.DetailedOriginResults;
    },
    routeTourSteps() {
      return [
        {
          content:
            'Enter your origin trip address and select the address match from the dropdown list or click on the Search button',
          header: {
            title: 'Origin Address Search field',
          },
          params: {
            placement: 'auto',
          },
          target: '#originAutocomplete',
        },
        {
          content:
            'Enter your destination trip address and select the address match from the dropdown list or click on the Search button',
          header: {
            title: 'Destination Address Search field',
          },
          params: {
            placement: 'auto',
          },
          target: '#destinationAutocomplete',
        },
        {
          content: 'Route description and it’s uses.',
          header: {
            title: 'Route Description',
          },
          target: '#route-description',
        },
      ];
    },
    shareUrl() {
      const { origin, pathname } = window.location;
      return `${origin}${pathname}`;
    },
    tourOptions() {
      return {
        highlight: true,
        labels: {
          buttonSkip: 'Skip tour (Esc)',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Thanks',
        },
        useKeyboardNavigation: true,
      };
    },
  },
  data() {
    return {
      searchOrginText: '',
      searchDestText: '',
      seoIdOrigin: null,
      seoIdDest: null,
    };
  },
  destroyed() {
    window.removeEventListener('iris:share-capability', this.shareViaWebShare);
    window.removeEventListener('iris:start-tour', this.startTour);
  },
  methods: {
    ...mapActions({
      LoadNavigationRoutes: 'routesStore/LoadNavigationRoutes',
    }),
    ...mapMutations('addressSearchStore', [
      'clearOriginResult',
      'clearDestResult',
    ]),
    autocompleteOrigin(searchText) {
      this.autocomplete('origin', searchText);
    },
    autocompleteDestination(searchText) {
      this.autocomplete('destination', searchText);
    },
    clearOrginAddress() {
      this.searchOriginText = '';
      this.seoIdOrigin = null;
      this.clearSuggestions();
      this.clearOriginResult();
    },
    clearDestAddress() {
      this.searchDestText = '';
      this.seoIdDest = null;
      this.clearSuggestions();
      this.clearDestResult();
    },
    getRoute() {
      const listOfPoints = [
        {
          latitude: this.DetailedOriginResults.location.lat,
          longitude: this.DetailedOriginResults.location.lng,
        },
        {
          latitude: this.DetailedDestResults.location.lat,
          longitude: this.DetailedDestResults.location.lng,
        },
      ];
      this.LoadNavigationRoutes(listOfPoints);
    },
    handleOk() {
      const Url = this.$refs.toCopy;
      Url.innerHTML = this.shareUrl;
      Url.select();
      document.execCommand('copy');
      this.$bvModal.msgBoxOk('URL Copied to Clipboard successfully', {
        title: 'Confirmation',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
    },
    isTourOn() {
      const { tour } = this.$route.query;
      if (!tour) {
        return false;
      }
      return /^true$/i.test(tour.toLowerCase());
    },
    onOriginChosen(data) {
      this.searchError = null;
      this.clearOrginAddress();
      this.clearOriginResult();
      this.clearSuggestions();
      this.searchOriginText = data.description;
      this.seoIdOrigin = data.seoid;
      const originData = {
        data,
        direction: 'origin',
      };
      this.doSearchDetail(originData);
    },
    onDestChosen(data) {
      this.searchError = null;
      this.clearDestAddress();
      this.clearDestResult();
      this.clearSuggestions();
      this.searchDestText = data.description;
      this.seoIdDest = data.seoid;
      const destData = {
        data,
        direction: 'destination',
      };
      this.doSearchDetail(destData);
    },
    shareViaWebShare() {
      if (navigator.share) {
        navigator
          .share({
            title: document.title,
            url: this.shareUrl,
          })
          .then()
          .catch(() => {
            // Handle the error!
          });
      } else {
        this.$refs['fallback-modal'].show();
      }
    },
    searchOrigin(searchText) {
      const payload = {
        direction: 'origin',
        searchText,
      };
      this.fetchAwsCredentials();
      this.doSearch(payload);
    },
    searchDestination(searchText) {
      const payload = {
        direction: 'destination',
        searchText,
      };
      this.fetchAwsCredentials();
      this.doSearch(payload);
    },
    startTour() {
      this.$tours.routeTour.start();
    },
    submitSearch() {
      this.searchLastEnteredText();
    },
    tourConfigure() {
      const hasSeenTour = JSON.parse(
        localStorage.getItem('hasSeenRouteTour'),
      );
      if (!hasSeenTour || this.isTourOn()) {
        this.startTour();
        localStorage.setItem('hasSeenRouteTour', true);
      }
    },
  },
  mixins: [AddressSearch, oidcAuth],
  mounted() {
    window.addEventListener('iris:share-capability', this.shareViaWebShare);
    window.addEventListener('iris:start-tour', this.startTour);
    const { query } = this.$route.query;
    if (query) {
      this.searchText = query;
    }
    this.tourConfigure();
  },
  name: 'Routes',
};
</script>

<style src="vue-tour/dist/vue-tour.css"></style>
<style scoped>
.overlay {
  position: absolute;
  width: 100%;
  z-index: 900;
}
.origin-input {
  z-index: 1000;
}

.routeTour {
  z-index: 500;
}

.v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.4);
}

#trust-us-card {
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 250px;
  border-bottom:0;
  border-color: white;
}
#trust-us-card .content{
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.remove-border-card {
  border-top: 0;
  border-color: white;
}

#share-fallback {
  pointer-events: none;
  background-color: lightgrey;
}
@media screen and (max-width: 400px) {
  .appCtrlButtons {
    display: none
  }
}
</style>
