<template>
  <div
    class="map-container"
    :id="leafletContainerId"
  >
    Loading Map...
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet/dist/leaflet';

const destinationIconUrl = require('@/assets/icons/destination.png');
const originIconUrl = require('@/assets/icons/origin.png');

export default {
  computed: {
    ...mapGetters({
      DetailedDestResults: 'addressSearchStore/detailedDestResults',
      DetailedOriginResults: 'addressSearchStore/detailedOriginResults',
      RouteLinepoints: 'routesStore/Data',
    }),
    center: () => [-28.4792625, 24.6727135],
    countryBoundingBox: () => [[-34.8191663551, 16.3449768409], [-22.0913127581, 32.830120477]],
    leafletContainerId: () => 'leaflet-container',
  },
  data() {
    return {
      agMapLoaded: false,
      destinationMarker: null,
      leafletMap: null,
      originMarker: null,
      selectedBaseType: 'vector',
      latLngBounds: null,
    };
  },
  methods: {
    checkIfAgMapLoaded() {
      this.agMapLoaded = !!window.AfriGIS;
      if (this.agMapLoaded) {
        return;
      }
      setTimeout(() => {
        this.checkIfAgMapLoaded();
      }, 500);
    },
    clearRoute() {
      if (!this.routeLayer || !this.leafletMap.hasLayer(this.routeLayer)) {
        return;
      }
      this.leafletMap.removeLayer(this.routeLayer);

      this.routeLayer = null;
    },
    removeMarker(marker) {
      if (marker) {
        this.leafletMap.removeLayer(marker);
      }
    },
    generateMarker(marker, coordinate, icon) {
      if (marker) {
        this.leafletMap.removeLayer(marker);
      }
      return L.marker(L.latLng([coordinate.lat, coordinate.lng]), {
        icon,
        draggable: false,
      }).addTo(this.leafletMap);
    },
    drawMarker(addressDetails, iconUrl, marker) {
      const popupOptions = {
        autoPanPaddingTopLeft: L.point(0, 70),
        offset: [1, -20],
      };
      const coordinate = addressDetails.location;
      const icon = this.generateIcon(iconUrl);
      return this.generateMarker(marker, coordinate, icon)
        .bindPopup(addressDetails.formatted_address, popupOptions);
    },
    drawRoute(newV) {
      this.routeLayer = L.polyline(newV, {
        color: '#003a63', weight: 5, smoothFactor: 1,
      }).addTo(this.leafletMap);

      this.latLngBounds.extend(this.routeLayer.getBounds());
      this.leafletMap.fitBounds(this.latLngBounds);
    },
    generateIcon(iconUrl) {
      return L.icon({
        iconAnchor: [20, 40],
        iconSize: [40, 40],
        iconUrl,
        shadowUrl: null,
      });
    },
    initMap() {
      if (this.leafletMap) {
        return;
      }

      this.leafletMap = window.AfriGIS.map(this.leafletContainerId, {
        center: L.latLng(this.center),
        authkey: process.env.VUE_APP_AG_LEAFLET_API_KEY,
        mapType: this.selectedBaseType,
        maxBounds: L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180)),
        maxBoundViscosity: 0,
        maxZoom: 18,
        minZoom: 3,
        zoom: 5,
        zoomControl: true,
      });
      this.leafletMap.fitBounds(this.countryBoundingBox);
      this.leafletMap.zoomControl.setPosition('bottomright');
    },
  },
  mounted() {
    this.checkIfAgMapLoaded();
  },
  name: 'LeafletMap',
  watch: {
    agMapLoaded(newV) {
      if (!newV) {
        return;
      }
      this.initMap();
    },
    DetailedDestResults(newV) {
      if (!newV) {
        this.removeMarker(this.destinationMarker);
        this.destinationMarker = null;
        return;
      }
      this.clearRoute();
      this.destinationMarker = this.drawMarker(newV, destinationIconUrl, this.destinationMarker);
      if (!this.latLngBounds) {
        this.latLngBounds = new L.LatLngBounds();
      }
      this.latLngBounds.extend(this.destinationMarker.getLatLng());
      this.leafletMap.fitBounds(this.latLngBounds);
    },
    DetailedOriginResults(newV) {
      if (!newV) {
        this.removeMarker(this.originMarker);
        this.originMarker = null;
        return;
      }
      this.clearRoute();
      this.originMarker = this.drawMarker(newV, originIconUrl, this.originMarker);
      if (!this.latLngBounds) {
        this.latLngBounds = new L.LatLngBounds();
      }
      this.latLngBounds.extend(this.originMarker.getLatLng());
      this.leafletMap.fitBounds(this.latLngBounds);
    },
    RouteLinepoints(newV) {
      this.clearRoute();
      if (!newV || !newV.length) {
        return;
      }
      this.drawRoute(newV);
      this.leafletMap.zoomOut();
    },
  },
};
</script>

<style>
.leaflet-bar a{
    background-color: var(--primary) !important;
    color: #fff !important;
  }
.map-container {
    position: relative;
    width: 100%;
    height: 70vh;
  }
.leaflet-interactive {
    cursor: grab;
  }
.leaflet-marker-icon {
    cursor: pointer;
  }
</style>
